<template>
  <b-modal
    id="add-scales-modal"
    hide-header
    hide-footer
    centered
    @hide="hideModal"
  >
    <div class="add-scales">
      <div class="add-scales__title">
        <h5>Связь с весами</h5>
        <e-button
          class="close"
          @click="hideModal()"
        >
          <img
            src="/img/icons/modal_close.svg"
            alt=""
          />
        </e-button>
      </div>
      <div class="add-scales__body">
        <div
          v-if="errorText"
          class="error-message"
        >
          <span>
            <img
              src="/img/icons/warning-red.svg"
              alt="warning"
            />
            {{ errorText }}
          </span>
        </div>
        <e-select
          v-model="model"
          label="Модель"
          :options="models"
        />
        <e-input
          v-if="model[0]?.type && model[0]?.type !== 'shtrih-m-pc-200c2'"
          v-model="ip"
          label="IP-адрес"
          placeholder="000.000.000.000"
        />
        <e-input
          v-if="model[0]?.type && model[0]?.type !== 'shtrih-m-pc-200c2'"
          v-model="port"
          label="Порт"
          placeholder="0000"
        />
        <e-input
          v-if="model[0]?.type && model[0]?.type === 'shtrih-m-pc-200c2'"
          v-model="path"
          label="Путь"
          :error="isErrorPath"
          placeholder="D:\Folder\.."
        />
      </div>
      <div class="add-scales__footer">
        <e-button
          size="m"
          variant="primary"
          :loading="loading"
          :disabled="loading"
          @click="addScales"
        >
          Сохранить
        </e-button>
        <e-button
          size="m"
          variant="outline-primary"
          @click="hideModal"
        >
          Отмена
        </e-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'AddScalesModal',
    props: {
      list_id: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        loading: false,
        models: [
          {
            id: 1,
            type: 'dibal_500',
            name: 'Дибал'
          },
          {
            id: 2,
            type: 'cas',
            name: 'CAS'
          },
          {
            id: 3,
            type: 'shtrih_m',
            name: 'Штрих М 15-2.5'
          },
          {
            id: 4,
            type: 'massa-k',
            name: 'МАССА-К'
          },
          {
            id: 5,
            type: 'shtrih-m-pc-200c2',
            name: 'Штрих М PC-200C2'
          }
        ],
        model: [],
        ip: '',
        port: '',
        path: '',
        errorText: '',
        isErrorPath: false
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    },
    methods: {
      hideModal() {
        this.$bvModal.hide('add-scales-modal')
        this.model = []
        this.ip = ''
        this.port = ''
        this.errorText = ''
        this.path = ''
        this.isErrorPath = false
      },

      pathValidate(path) {
        const currentSymbol = {
          '/': 0,
          '\\': 0
        }
        const lastItemCatalog = path.charAt(path.length - 1)
        for (let i = 0; i < path.length; i++) {
          if (path[i] === '/') {
            currentSymbol['/'] += 1
          }
          if (path[i] === '\\') {
            currentSymbol['\\'] += 1
          }
        }
        if (currentSymbol['/'] > 0 && currentSymbol['\\'] > 0) return true
        if (currentSymbol['/'] === 0 && currentSymbol['\\'] === 0) return true

        if (currentSymbol[lastItemCatalog] !== undefined) {
          return path
        } else {
          if (currentSymbol['/'] > 0) {
            path = `${path + '/'}`
            return path
          }
          if (currentSymbol['\\'] > 0) {
            path = `${path + '\\'}`
            return path
          }
        }
      },

      async addScales() {
        this.isErrorPath = false
        if (this.path !== '' && this.pathValidate(this.path) === true) {
          this.isErrorPath = true
          return
        }

        try {
          this.loading = true
          await this.$apollo.mutate({
            mutation: require('../gql/createDevice.graphql'),
            fetchPolicy: 'no-cache',
            variables: {
              input: {
                ip: this.ip === '' ? null : this.ip,
                branch: this.currentBranch.id,
                port: this.port === '' ? null : this.port,
                type: this.model?.[0].type,
                list: this.list_id,
                in_path: this.path === '' ? null : this.path,
                out_path: this.path === '' ? null : this.path
              }
            }
          })
          this.hideModal()
          this.$emit('refetch')
          this.loading = false
        } catch (e) {
          this.errorText = e?.graphQLErrors[0].message
          this.$noty.error(e?.graphQLErrors[0].message)
          this.loading = false
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .add-scales {
    &__title {
      margin-bottom: 30px;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      display: flex;
      h5 {
        font-family: 'Gilroy-Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #313131;
      }
      ::v-deep button {
        padding: 0 !important;
      }
    }
    &__body {
      margin-bottom: 32px;
      ::v-deep p.label {
        font-family: 'Gilroy-Regular', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #313131;
      }
      ::v-deep label {
        font-family: 'Gilroy-Regular', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #313131;
      }
      ::v-deep .input-block {
        margin-top: 16px;
      }
    }
    &__footer {
      display: flex;
      gap: 16px;
      padding-bottom: 22px;
    }
  }
  ::v-deep {
    .ms__dropdown-item.active {
      p {
        color: #00a3ff;
      }
    }
  }
  .error-message {
    background: #fffafa;
    padding: 7px 9px;
    border: 1px solid #ef5250;
    border-radius: 4px;
    margin-bottom: 22px;
    span {
      img {
        margin-right: 7px;
      }
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #313131;
    }
  }
</style>
