var render = function render(){
  var _vm$model$, _vm$model$2, _vm$model$3, _vm$model$4, _vm$model$5, _vm$model$6;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "add-scales-modal",
      "hide-header": "",
      "hide-footer": "",
      "centered": ""
    },
    on: {
      "hide": _vm.hideModal
    }
  }, [_c('div', {
    staticClass: "add-scales"
  }, [_c('div', {
    staticClass: "add-scales__title"
  }, [_c('h5', [_vm._v("Связь с весами")]), _c('e-button', {
    staticClass: "close",
    on: {
      "click": function ($event) {
        return _vm.hideModal();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/modal_close.svg",
      "alt": ""
    }
  })])], 1), _c('div', {
    staticClass: "add-scales__body"
  }, [_vm.errorText ? _c('div', {
    staticClass: "error-message"
  }, [_c('span', [_c('img', {
    attrs: {
      "src": "/img/icons/warning-red.svg",
      "alt": "warning"
    }
  }), _vm._v(" " + _vm._s(_vm.errorText) + " ")])]) : _vm._e(), _c('e-select', {
    attrs: {
      "label": "Модель",
      "options": _vm.models
    },
    model: {
      value: _vm.model,
      callback: function ($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  }), (_vm$model$ = _vm.model[0]) !== null && _vm$model$ !== void 0 && _vm$model$.type && ((_vm$model$2 = _vm.model[0]) === null || _vm$model$2 === void 0 ? void 0 : _vm$model$2.type) !== 'shtrih-m-pc-200c2' ? _c('e-input', {
    attrs: {
      "label": "IP-адрес",
      "placeholder": "000.000.000.000"
    },
    model: {
      value: _vm.ip,
      callback: function ($$v) {
        _vm.ip = $$v;
      },
      expression: "ip"
    }
  }) : _vm._e(), (_vm$model$3 = _vm.model[0]) !== null && _vm$model$3 !== void 0 && _vm$model$3.type && ((_vm$model$4 = _vm.model[0]) === null || _vm$model$4 === void 0 ? void 0 : _vm$model$4.type) !== 'shtrih-m-pc-200c2' ? _c('e-input', {
    attrs: {
      "label": "Порт",
      "placeholder": "0000"
    },
    model: {
      value: _vm.port,
      callback: function ($$v) {
        _vm.port = $$v;
      },
      expression: "port"
    }
  }) : _vm._e(), (_vm$model$5 = _vm.model[0]) !== null && _vm$model$5 !== void 0 && _vm$model$5.type && ((_vm$model$6 = _vm.model[0]) === null || _vm$model$6 === void 0 ? void 0 : _vm$model$6.type) === 'shtrih-m-pc-200c2' ? _c('e-input', {
    attrs: {
      "label": "Путь",
      "error": _vm.isErrorPath,
      "placeholder": "D:\\Folder\\.."
    },
    model: {
      value: _vm.path,
      callback: function ($$v) {
        _vm.path = $$v;
      },
      expression: "path"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "add-scales__footer"
  }, [_c('e-button', {
    attrs: {
      "size": "m",
      "variant": "primary",
      "loading": _vm.loading,
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.addScales
    }
  }, [_vm._v(" Сохранить ")]), _c('e-button', {
    attrs: {
      "size": "m",
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v(" Отмена ")])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }