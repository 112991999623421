<template>
  <div class="scale-content">
    <add-scales-modal
      :list_id="$route.query.id ? this.$route.query.id : this.$route.params.id"
      @refetch="refetch"
    />
    <div class="d-flex">
      <b-button
        class="ml-auto"
        variant="primary"
        @click="update"
      >
        Сохранить
      </b-button>
    </div>

    <b-card
      no-body
      style="margin-top: 20px"
    >
      <div class="main-list">Основные данные</div>
      <div class="basic-container">
        <e-input
          v-model="list_name"
          :error="errorListName"
          placeholder="Название"
          label="Название"
          error_text="Название не может быть пустым"
        />
        <e-select
          v-model="active_branch"
          :error="errorBranch"
          :options="branches"
          placeholder="Магазин"
          label="Магазин"
        />
        <div class="active-list">
          <span>
            Видимость
            <img
              v-b-tooltip:hover
              title="Список будет доступен для добавления в него товаров"
              src="/img/icons/icon-info.svg"
              alt="info"
            />
          </span>
          <b-form-checkbox
            :checked="visible_list"
            name="check-button"
            switch
            @change="visible_list = !visible_list"
          />
        </div>
      </div>
    </b-card>
    <b-card
      no-body
      class="table-container"
      style="margin-top: 20px"
    >
      <div class="main-list">Связанные весы</div>
      <div class="table-header">
        <e-input
          search-icon
          placeholder="Поиск"
        />
        <b-tooltip
          v-if="!isId"
          target="add-scales"
          triggers="hover"
        >
          Сохраните список для добавления весов
        </b-tooltip>
        <div id="add-scales">
          <e-button
            plus
            :variant="variantScales"
            size="m"
            :disabled="!isId"
            @click="addScales"
          >
            Добавить весы
          </e-button>
        </div>
      </div>
      <div class="container-table position-relative">
        <resizable-table
          table_name="scales"
          :busy="false"
          :items="devices"
          :default_fields="default_fields"
        >
          <template #head_id>
            <e-checkbox
              :checked="select_all"
              @click="select_all = !select_all"
            />
          </template>
          <template #body_id="{ item }">
            <e-checkbox
              :checked="active(item)"
              @click="selectItem(item)"
            />
          </template>
          <template #body_in_path="{ item }">
            <div class="table-text-left">{{ item.in_path }}</div>
          </template>
          <template #body_loaded="{ item }">
            <b-form-checkbox
              :checked="item.active"
              name="check-button"
              switch
              @change="(val) => setStatus(val, item)"
            />
          </template>
          <template #body_status="data">
            <b-badge :variant="`${data.item.status ? 'outline-success' : 'outline-danger'}`">
              <div class="bage-status">
                <span class="text">{{ data.item.status ? 'Связть есть' : 'Нет связи' }}</span>
              </div>
            </b-badge>
          </template>
          <template #body_model="{ item }">
            {{ getNameScales(item?.type) }}
          </template>
        </resizable-table>
        <table-navbar
          style="position: absolute; left: 0; bottom: 0; right: 0"
          :items="selected"
          hide_print
          items_name="list_scales"
          @listScalesLoad="listScalesLoad"
          @remove_items="deleteScales"
        />
        <!--        <custom-navbar-->
        <!--          style="position: absolute; left: 0; bottom: 0; right: 0"-->
        <!--          :items="selected"-->
        <!--          item_name="Позиция"-->
        <!--          :actions="[{ id: 'delete', label: 'Удалить', max: 1000 }]"-->
        <!--          @delete="deleteScales"-->
        <!--          @checkConnection="checkConnection"-->
        <!--          @clear="selected = []"-->
        <!--        />-->
      </div>
    </b-card>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { BranchModel } from '@/models/branch.model'
  import { DeviceModel } from '@/models/device.model'
  import AddScalesModal from '@/views/settings/modal/AddScalesModal'
  import CustomNavbar from '@/views/core/sidebar/components/CustomNavbar'
  import TableNavbar from '@/views/shared/components/table/TableNavbar'

  export default {
    name: 'Scale',
    components: { TableNavbar, CustomNavbar, AddScalesModal },
    apollo: {
      DevicesByList: {
        query: require('../gql/DevicesByList.graphql'),
        variables() {
          return {
            id: this.$route.query.id ? this.$route.query.id : this.$route.params.id
          }
        },
        result({ data }) {
          this.devices = data?.DevicesByList ?? []
        },
        skip() {
          return !this.isId
        }
      }
    },
    data() {
      return {
        first: true,
        visible_list: true,
        selected: [],
        errorBranch: false,
        errorListName: false,
        list_name: '',
        active_branch: [],
        devices: [],
        select_all: false,
        selected_branch: [],
        device: new DeviceModel(),
        storage_list: [],
        integrations: [],
        plu_list: [],
        scale_names: {
          shtrih_m: 'Штрих М 15-2.5',
          dibal_500: 'Dibal 525'
        },
        default_fields: [
          {
            key: 'id',
            label: '',
            checked: true,
            width: 50
          },
          {
            key: 'model',
            label: 'Модель',
            checked: true
          },
          {
            key: 'ip',
            label: 'IP-адрес',
            checked: true
          },
          {
            key: 'port',
            label: 'Port',
            checked: true
          },
          {
            key: 'in_path',
            label: 'Путь',
            checked: true,
            width: 250
          },
          {
            key: 'loaded',
            label: 'Загрузка списка',
            checked: true
          },
          {
            key: 'status',
            label: 'Состояние',
            checked: true
          }
        ]
      }
    },
    watch: {
      visible_list() {
        if (this.first) return
        if (this.$route.query?.id) {
          this.$router.replace({
            path: this.$route.path,
            query: {
              id: this.$route.query?.id,
              name: this.$route.query?.name ?? '',
              visible: this.visible_list
            }
          })
          this.setBreadcrumbs({
            ...this.getBreadcrumbs,
            scales: { ...this.getBreadcrumbs.scales, visible: this.visible_list }
          })
        }
        if (this.$route.query?.id) this.update()
      },
      select_all() {
        if (this.select_all) {
          this.selected = this.devices
        } else {
          this.selected = []
        }
      },
      list_name() {
        if (this.errorListName) {
          this.errorListName = false
        }
      },
      active_branch() {
        if (this.errorBranch) {
          this.errorBranch = false
        }
      }
    },
    beforeMount() {
      this.list_name = this.$route?.query?.name ?? ''
      this.visible_list = this.$route?.query?.visible
      if (this.$route.query?.branch) {
        const branch = this.branches.find((obj) => obj.id === this.$route.query?.branch)
        this.active_branch = [branch]
      } else {
        if (this.$route.query?.id) this.active_branch = [this.currentBranch]
      }
      this.breadcrumbs()
    },
    destroyed() {
      this.setBreadcrumbs({})
    },
    mounted() {
      this.breadcrumbs()
      this.device = new DeviceModel({
        tags: ['scales'],
        branch: new BranchModel(this.currentBranch),
        type: 'shtrih_m'
      })
      this.$nextTick(() => {
        this.first = false
      })
    },
    methods: {
      ...mapActions({
        setBreadcrumbs: 'breadcrumbs/set_current'
      }),
      breadcrumbs() {
        if (this.$route.query?.id) {
          this.setBreadcrumbs({
            is_go_back: true,
            scales: { name: this.$route?.query?.name ?? '', visible: this.visible_list }
          })
        } else {
          this.setBreadcrumbs({ is_go_back: true, scale: { visible: this.visible_list } })
        }
      },
      listScalesLoad(item) {
        this.setStatus(!item.active, item)
      },
      async deleteScales(val) {
        try {
          await this.$apollo.mutate({
            mutation: require('../gql/RemoveDevice.graphql'),
            variables: {
              ids: val.map((obj) => obj.id)
            }
          })
          this.refetch()
          this.selected = []
          this.$noty.show('Успешно')
        } catch (e) {
          console.log(e)
          this.$noty.error('При удалении произошла ошибка')
        }
      },
      selectItem(el) {
        if (this.selected.find((obj) => obj.id === el.id)) {
          this.selected = this.selected.filter((item) => item.id !== el.id)
        } else {
          this.selected.push(el)
        }
      },
      active(item) {
        return this.selected.find((obj) => obj.id === item.id)
      },
      refetch() {
        this.$apollo.queries.DevicesByList.refetch()
      },
      async setStatus(val, item) {
        if (item.port) {
          item.port = item.port.toString()
        }

        await this.$apollo.mutate({
          mutation: require('../gql/updateDevice.graphql'),
          variables: {
            input: {
              ...item,
              active: val,
              branch: item?.branch?.id,
              storage: item?.storage?.id
            }
          }
        })
        this.selected = []
        this.refetch()
      },
      getNameScales(type) {
        if (type === 'dibal_500') {
          return 'Дибал 525'
        } else if (type === 'cas') {
          return 'CAS'
        } else if (type === 'shtrih_m') {
          return 'Штрих-М'
        } else if (type === 'shtrih-m-pc-200c2') {
          return 'Штрих М PC-200C2'
        }
        return ''
      },
      addScales() {
        this.$bvModal.show('add-scales-modal')
      },
      async getStorage(id) {
        this.device.branch = this.branches.find((el) => el.id === id)
        const { data } = await this.$apollo.query({
          query: require('../gql/getStorages.gql'),
          variables: { branch: id }
        })

        this.storage_list = data.Storages || []
      },
      async update() {
        if (!this.list_name && !this.active_branch?.[0]?.id) {
          this.errorListName = true
          this.errorBranch = true
          return this.$noty.error('Введите название списка и выберите магазин')
        }
        if (!this.list_name) {
          this.errorListName = true
          return this.$noty.error('Название списка не может быть пустым')
        }
        if (!this.active_branch?.[0]?.id) {
          this.errorBranch = true
          return this.$noty.error('Выберите магазин')
        }
        if (this.$route.query?.id) {
          try {
            await this.$apollo.mutate({
              mutation: require('../gql/UpdatePluList.graphql'),
              variables: {
                input: {
                  id: this.$route.query?.id,
                  branch: this.active_branch[0]?.id,
                  name: this.list_name,
                  visible: this.visible_list
                }
              }
            })
            setTimeout(
              () =>
                this.setBreadcrumbs({
                  is_go_back: true,
                  scales: { name: this.list_name, visible: this.visible_list }
                }),
              0
            )
            this.$noty.show('Данные успешно обновленны')
          } catch (e) {
            console.log(e)
            this.$noty.error('При обновлении списка произошла ошибка')
          }
        } else {
          try {
            const { data } = await this.$apollo.mutate({
              mutation: require('../gql/CreatePluList.graphql'),
              variables: {
                input: {
                  branch: this.active_branch[0]?.id,
                  name: this.list_name,
                  visible: this.visible_list
                }
              }
            })
            await this.$router.replace({
              name: 'devices.scale.new',
              query: { id: data?.CreatePluList?.id, name: data?.CreatePluList?.name, branch: this.active_branch[0]?.id }
            })
            this.$noty.show('Список успешно создан')
            return this.breadcrumbs()
          } catch (e) {
            console.log(e)
            this.$noty.error('При создании списка произошла ошибка')
          }
        }
      },
      setStorage(id) {
        this.device.storage = this.storage_list.find((el) => el.id === id)
      },
      go_back() {
        const backPage = this.$route.meta.parrent
        if (backPage) this.$router.push({ name: backPage })
        else this.$router.back()
      }
    },

    computed: {
      ...mapGetters({
        branches: 'settings/getBranchList',
        currentBranch: 'settings/getCurrentBranch',
        getBreadcrumbs: 'breadcrumbs/current'
      }),
      variantScales() {
        return this.$route.query?.id ? 'primary' : 'default'
      },
      isId() {
        return this.$route.query?.id
      }
    }
  }
</script>

<style lang="scss" scoped>
  .table-text-left {
    text-align: left;
  }
  .scale-content {
    max-width: 1150px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .main-list {
    font-family: 'Gilroy-Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #bcbcbc;
  }

  .basic-container {
    margin-top: 18px;
    display: flex;
    gap: 20px;
  }
  .container-table {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .tags-header {
    background-color: transparent;
    border: none;
    padding-left: 0.625rem;
    margin-top: 30px;
  }

  .tag-item {
    background: #ffffff !important;
    border: 1px solid #e1e1e1 !important;
    border-radius: 100px !important;
    margin-right: 10px;
  }

  .tag-item-active {
    background: #00a3ff !important;
    margin-right: 16px;
  }

  .row-item {
    margin-right: 18px;
  }
  .table-header {
    margin-top: 18px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e1e1e1 !important;
    border-left: 1px solid #e1e1e1 !important;
    border-right: 1px solid #e1e1e1 !important;
    ::v-deep .input-block {
      max-width: 270px !important;
      width: 100%;
      margin-right: 20px;
    }
  }
  ::v-deep .bage-status {
    display: flex;
    align-items: center;
    height: 24px;
    .text {
      font-size: 14px;
    }
    .numbers {
      width: 23px;
      height: 18px;
      padding: 2px 4px;
      margin-left: 6px;
      background: #e53835;
      border-radius: 4px;
      color: #fff;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }
  }
  .active-list {
    margin-right: 20px;
    span {
      display: flex;
      img {
        cursor: pointer;
        transform: scale(1.2);
        margin-left: 6px;
      }
      margin-bottom: 6px;
      color: #313131;
      font-size: 12px;
      font-weight: 400;
      font-style: normal;
      font-family: 'Gilroy-Regular', sans-serif;
      line-height: 16px;
    }
    ::v-deep .custom-switch .custom-control-label::before {
      height: 22px;
      left: -2.25rem;
      width: 44px;
      pointer-events: all;
      border-radius: 24.5rem;
    }
    ::v-deep .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
      background-color: #fff;
      transform: translateX(1.5rem);
    }
    ::v-deep .custom-switch .custom-control-label::after {
      width: 16px;
      height: 16px;
    }
    ::v-deep .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
      transform: translateX(1.4rem) !important;
    }
    ::v-deep .custom-switch .custom-control-label::after {
      top: calc(0.25rem + 3px) !important;
      left: calc(-2.25rem + 3px);
    }
  }
  .table-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  ::v-deep .badge-danger {
    background: #ffebee !important;
    color: #e53835 !important;
  }

  ::v-deep .badge-outline-danger {
    border: 1px solid #e53835;
    border-radius: 3px;
    background: #ffffff !important;
    color: #e53835 !important;
  }

  ::v-deep .badge-outline-success {
    border: 1px solid #00cb91;
    border-radius: 3px;
    background: #ffffff !important;
    color: #00cb91 !important;
  }
  ::v-deep .table-inner {
    border-left: 1px solid #e1e1e1 !important;
    border-right: 1px solid #e1e1e1 !important;
  }
  ::v-deep table .row-field {
    border-bottom: 1px solid #e1e1e1 !important;
  }
  ::v-deep table tr td {
    border-right: none;
    padding: 16px;
  }
  ::v-deep .custom-switch .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #00cb91;
    background-color: #00cb91;
  }
  ::v-deep .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #adb5bd !important;
  }
  ::v-deep .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none !important;
  }
  ::v-deep .error_text {
    margin-bottom: 0 !important;
  }
</style>
