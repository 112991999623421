var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "scale-content"
  }, [_c('add-scales-modal', {
    attrs: {
      "list_id": _vm.$route.query.id ? this.$route.query.id : this.$route.params.id
    },
    on: {
      "refetch": _vm.refetch
    }
  }), _c('div', {
    staticClass: "d-flex"
  }, [_c('b-button', {
    staticClass: "ml-auto",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.update
    }
  }, [_vm._v(" Сохранить ")])], 1), _c('b-card', {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "main-list"
  }, [_vm._v("Основные данные")]), _c('div', {
    staticClass: "basic-container"
  }, [_c('e-input', {
    attrs: {
      "error": _vm.errorListName,
      "placeholder": "Название",
      "label": "Название",
      "error_text": "Название не может быть пустым"
    },
    model: {
      value: _vm.list_name,
      callback: function ($$v) {
        _vm.list_name = $$v;
      },
      expression: "list_name"
    }
  }), _c('e-select', {
    attrs: {
      "error": _vm.errorBranch,
      "options": _vm.branches,
      "placeholder": "Магазин",
      "label": "Магазин"
    },
    model: {
      value: _vm.active_branch,
      callback: function ($$v) {
        _vm.active_branch = $$v;
      },
      expression: "active_branch"
    }
  }), _c('div', {
    staticClass: "active-list"
  }, [_c('span', [_vm._v(" Видимость "), _c('img', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip:hover",
      arg: "hover"
    }],
    attrs: {
      "title": "Список будет доступен для добавления в него товаров",
      "src": "/img/icons/icon-info.svg",
      "alt": "info"
    }
  })]), _c('b-form-checkbox', {
    attrs: {
      "checked": _vm.visible_list,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        _vm.visible_list = !_vm.visible_list;
      }
    }
  })], 1)], 1)]), _c('b-card', {
    staticClass: "table-container",
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "main-list"
  }, [_vm._v("Связанные весы")]), _c('div', {
    staticClass: "table-header"
  }, [_c('e-input', {
    attrs: {
      "search-icon": "",
      "placeholder": "Поиск"
    }
  }), !_vm.isId ? _c('b-tooltip', {
    attrs: {
      "target": "add-scales",
      "triggers": "hover"
    }
  }, [_vm._v(" Сохраните список для добавления весов ")]) : _vm._e(), _c('div', {
    attrs: {
      "id": "add-scales"
    }
  }, [_c('e-button', {
    attrs: {
      "plus": "",
      "variant": _vm.variantScales,
      "size": "m",
      "disabled": !_vm.isId
    },
    on: {
      "click": _vm.addScales
    }
  }, [_vm._v(" Добавить весы ")])], 1)], 1), _c('div', {
    staticClass: "container-table position-relative"
  }, [_c('resizable-table', {
    attrs: {
      "table_name": "scales",
      "busy": false,
      "items": _vm.devices,
      "default_fields": _vm.default_fields
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('e-checkbox', {
          attrs: {
            "checked": _vm.select_all
          },
          on: {
            "click": function ($event) {
              _vm.select_all = !_vm.select_all;
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('e-checkbox', {
          attrs: {
            "checked": _vm.active(item)
          },
          on: {
            "click": function ($event) {
              return _vm.selectItem(item);
            }
          }
        })];
      }
    }, {
      key: "body_in_path",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "table-text-left"
        }, [_vm._v(_vm._s(item.in_path))])];
      }
    }, {
      key: "body_loaded",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "checked": item.active,
            "name": "check-button",
            "switch": ""
          },
          on: {
            "change": function (val) {
              return _vm.setStatus(val, item);
            }
          }
        })];
      }
    }, {
      key: "body_status",
      fn: function (data) {
        return [_c('b-badge', {
          attrs: {
            "variant": `${data.item.status ? 'outline-success' : 'outline-danger'}`
          }
        }, [_c('div', {
          staticClass: "bage-status"
        }, [_c('span', {
          staticClass: "text"
        }, [_vm._v(_vm._s(data.item.status ? 'Связть есть' : 'Нет связи'))])])])];
      }
    }, {
      key: "body_model",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.getNameScales(item === null || item === void 0 ? void 0 : item.type)) + " ")];
      }
    }])
  }), _c('table-navbar', {
    staticStyle: {
      "position": "absolute",
      "left": "0",
      "bottom": "0",
      "right": "0"
    },
    attrs: {
      "items": _vm.selected,
      "hide_print": "",
      "items_name": "list_scales"
    },
    on: {
      "listScalesLoad": _vm.listScalesLoad,
      "remove_items": _vm.deleteScales
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }